import { createI18n } from 'vue-i18n'
import { Locale } from 'vant'
import zh from 'view-ui-plus/dist/locale/zh-CN'
import en from 'view-ui-plus/dist/locale/en-US'
// 引入英文语言包
import vantEnUS from 'vant/es/locale/lang/en-US'
// 引入中文语言包
import vantZhCN from 'vant/es/locale/lang/zh-CN'

// 自定义的语言包
import customZh from './locales/zh-CN'
import customEn from './locales/en-US'

// 获取用户缓存中设置的语言
// 如果缓存中没有设置，则获取浏览器首选语言
// const useLanguage = getStorage('language') || navigator.language || 'zh-CN'

// 暂时只支持简易中文
const useLanguage = 'zh-CN'

const globalI18n = createI18n({
  legacy: false,
  // 默认语言
  locale: useLanguage,
  // 如果当前语言没有某个翻译，使用的语言
  fallbackLocale: 'zh-CN',
  messages: {
    'zh-CN': Object.assign(zh, customZh),
    'en-US': Object.assign(en, customEn)
  }
})

export const setVantI18n = (lang: 'zh-CN' | 'en-US') => {
  if (lang === 'en-US') {
    Locale.use('en-US', vantEnUS)
  } else if (lang === 'zh-CN') {
    Locale.use('zh-CN', vantZhCN)
  }
}

export const i18n = globalI18n
