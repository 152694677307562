<template>
  <MyIcon v-if="iconType === 'MyIcon'" :name="iconName" :color="iconColor" :size="iconSize" />
  <Icon v-else :type="iconName" :color="iconColor" :size="iconSize" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import MyIcon from '../my-icon.vue'

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  size: {
    type: Number,
    default: null
  }
})

const iconType = computed(() => {
  return props.type.indexOf('_') === 0 ? 'MyIcon' : 'Icon'
})
const iconName = computed(() => {
  return iconType.value === 'MyIcon' ? getCustomIconName(props.type) : props.type
})

const iconSize = computed(() => {
  return props.size || undefined
})

const iconColor = computed(() => {
  return props.color || ''
})

const getCustomIconName = (iconName: string) => {
  return iconName.slice(1)
}
</script>

<style></style>
