<template>
  <Dropdown
    ref="dropdown"
    :class="hideTitle ? '' : 'collased-menu-dropdown'"
    :transfer="hideTitle"
    :placement="placement"
    @on-click="handleClick"
  >
    <a
      class="drop-menu-a"
      type="text"
      :style="!hideTitle ? { textAlign: 'left' } : {}"
      @mouseover="handleMousemove($event, children)"
    >
      <common-icon :size="rootIconSize" :color="textColor" :type="parentItem.icon" />
      <span v-if="!hideTitle" class="menu-title">{{ showTitle(parentItem) }} </span>
      <Icon v-if="!hideTitle" style="float: right" type="ios-arrow-forward" :size="16" />
    </a>
    <template #list>
      <DropdownMenu>
        <template v-for="child in children">
          <collapsed-menu
            v-if="showChildren(child)"
            :key="`drop-${child.name}`"
            :icon-size="iconSize"
            :parent-item="child"
          />
          <template v-else>
            <DropdownItem :key="`drop-${child.name}`" :name="child.name">
              <common-icon :size="iconSize" :type="child.icon" />
              <span class="menu-title">{{ showTitle(child) }}</span>
            </DropdownItem>
          </template>
        </template>
      </DropdownMenu>
    </template>
  </Dropdown>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { showTitle, findNodeUpperByClasses } from '@/libs/utils'
import CommonIcon from '../common-icon/common-icon.vue'

const emit = defineEmits(['on-click'])

const props = defineProps({
  parentItem: {
    type: Object,
    default: () => {
      return {}
    }
  },
  theme: {
    type: String,
    default: ''
  },
  iconSize: {
    type: Number,
    default: null
  },
  hideTitle: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  rootIconSize: {
    type: Number,
    default: 16
  }
})

const placement = ref('right-end')

const dropdown = ref()

const children = computed(() => {
  return props.parentItem.children
})
const textColor = computed(() => {
  return props.theme === 'dark' ? '#fff' : '#495060'
})

const showChildren = (item: any) => {
  return item.children && (item.children.length > 1 || (item.meta && item.meta.showAlways))
}

const handleClick = (name: string) => {
  emit('on-click', name)
}
const handleMousemove = (event: any, children: any) => {
  const { pageY } = event
  const height = children.length * 38
  const isOverflow = pageY + height < window.innerHeight
  placement.value = isOverflow ? 'right-start' : 'right-end'
}

onMounted(() => {
  if (dropdown.value) {
    let dropdownEl = findNodeUpperByClasses(dropdown.value.$el, ['ivu-select-dropdown', 'ivu-dropdown-transfer'])
    if (dropdownEl) {
      dropdownEl.style.overflow = 'visible'
    }
  }
})
</script>
