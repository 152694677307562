<template>
  <div class="home-view">
    <div class="zzgf-xzt">
      <h3 class="title">银烛秋光冷画屏，轻罗小扇扑流萤。</h3>
      <h3 class="title">天阶夜色凉如水，卧看牵牛织女星。</h3>
      <div class="desc flex al-i-c ju-c-end" @click="toLogin">
        <span class="m-r-4" style="text-decoration: underline">去登录</span>
        <Icon type="md-log-in" :size="30" style="margin-top: -4px" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const toLogin = () => {
  router.replace({
    name: 'login'
  })
}
onMounted(() => {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    toLogin()
  }
})
</script>

<style lang="less" scoped>
.home-view {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('@/assets/images/login-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    font-size: 80px;
    color: #fff;
  }
  .desc {
    font-size: 30px;
    color: #f5f5f5;
    margin-top: 50px;
    .line {
      width: 30px;
      height: 1px;
      background-color: #fff;
    }
  }
}
</style>
