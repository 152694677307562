import { io, Socket } from 'socket.io-client'
import { encipherString, decryptAES, deepClone } from '@/libs/utils'

import globalConfig from '@/config/index'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const socketBaseUrl = globalConfig.socketBaseUrl[env]
const aesConfig = globalConfig.aesConfig[env]
import { SocketOptions, SocketMsgContent, SocketMessage, AESSocketMessage } from '@/types/common-types'
import { EventBus } from '@/eventBus'

class SocketClient {
  private socket: Socket
  private socketEventList: string[] = []

  constructor(private options: SocketOptions) {
    this.socket = io(socketBaseUrl, this.options)
    this.setupListeners()
  }

  private setupListeners() {
    this.socket.on('connect', () => {
      console.log('Connected to server')
      EventBus.emit('SOCKET_CONNECT_PUBLIC')
      EventBus.emit('SOCKET_CONNECT')
    })

    this.socket.on('disconnect', () => {
      console.log('Disconnected from server')
      EventBus.emit('SOCKET_DISCONNECT_PUBLIC')
    })

    this.socket.on('message', (message: SocketMessage | AESSocketMessage) => {
      // console.log('message:', message.from)
      // console.log('message:', message.msgContent)
      console.log('message:', message)
      const decryptData = deepClone(message)
      if (aesConfig.open) {
        const msgContent: string = (message as AESSocketMessage).msgContent
        decryptData.msgContent = decryptAES(msgContent)
      }
      EventBus.emit('NEW_MESSAGE', decryptData)
      EventBus.emit('PLAY_HINT_AUDIO')
    })

    this.socket.on('release-new-version', (message: SocketMessage | AESSocketMessage) => {
      console.log('message:', message)
      const decryptData = deepClone(message)
      if (aesConfig.open) {
        const msgContent: string = (message as AESSocketMessage).msgContent
        decryptData.msgContent = decryptAES(msgContent)
      }
      EventBus.emit('RELEASE_NEW_VERSION', decryptData)
    })
  }

  // 连接
  public connect() {
    this.socket.connect()
  }

  // 关闭连接
  public closeConnection() {
    this.socket.disconnect()
  }

  // 触发事件
  public emit(eventName: string, msg: any) {
    if (msg) {
      // 当存在msg的时候
      const postMsgData: any = {
        from: 'client',
        msgContent: msg
      }
      if (aesConfig.open) {
        postMsgData.msgContent = encipherString(msg)
      }
      this.socket.emit(eventName, postMsgData)
    } else {
      this.socket.emit(eventName)
    }
  }

  // 添加监听事件
  public addEvent(eventName: string, callback: (message: SocketMessage | AESSocketMessage) => void) {
    if (!this.socketEventList.includes(eventName)) {
      this.socket.on(eventName, (message: SocketMessage | AESSocketMessage) => {
        console.log(eventName, '====> ', 'message:', message)
        const decryptData = deepClone(message)
        if (aesConfig.open) {
          const msgContent: string = (message as AESSocketMessage).msgContent
          decryptData.msgContent = decryptAES(msgContent)
        }
        console.log(eventName, '解密数据====>', decryptData)
        callback(decryptData)
      })
      this.socketEventList.push(eventName)
    }
  }

  // 移除监听事件
  public offEvent(eventName: string, callback: (message: SocketMessage | AESSocketMessage) => void) {
    if (this.socketEventList.includes(eventName)) {
      this.socket.off(eventName, callback)
      this.socketEventList.splice(this.socketEventList.indexOf(eventName), 1)
    }
  }

  // 发送消息
  public sendMessage(msgContent: SocketMsgContent) {
    if (aesConfig.open) {
      const encryptedText = encipherString(msgContent)
      const message: AESSocketMessage = {
        from: 'client',
        msgContent: encryptedText
      }
      this.socket.emit('send-message', message)
    } else {
      const message: SocketMessage = {
        from: 'client',
        msgContent: msgContent
      }
      this.socket.emit('send-message', message)
    }
  }
}

export default SocketClient
