import HttpRequest from '@/libs/http-instance'
import globalConfig from '@/config/index'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const baseUrl: string = globalConfig.baseUrl[env]
const axiosServer: HttpRequest = new HttpRequest(baseUrl)

import type { LoginReq, LoginRes } from '@/types/apis-types/common-apis-types'
import type {
  GetUserInfoRes,
  CreateCompanyReq,
  CreateCompanyRes,
  GetCompanyListReq,
  GetCompanyListRes,
  ApplyCreateCompanyReq,
  VersionReq,
  VersionRes,
  GetVersionReq,
  GetVersionRes,
  ReleaseNewVersionReq,
  ReleaseNewVersionRes,
  ApplyCompanyStatusAuditReq,
  GetPermissionListRes,
  CreatOrUpdatePermissionReq,
  CreatOrUpdatePermissionRes,
  DeletePermissionReq,
  DeletePermissionRes
} from '@/types/apis-types/platform-apis-types'
import { CommApiRes, CommGetDatReq } from '@/types/apis-types/common-apis-types'

interface PlatformApis {
  /**
   * 登录接口
   * */
  login(data: LoginReq): Promise<LoginRes>
  /**
   * 获取用户信息
   * */
  getUserInfo(): Promise<GetUserInfoRes>
  /**
   * 创建企业
   * */
  createCompany(data: CreateCompanyReq): Promise<CreateCompanyRes>
  /**
   * 获取企业
   * */
  getCompanyList(data: GetCompanyListReq): Promise<GetCompanyListRes>
  /**
   * 更新企业
   * */
  updateCompany(data: CreateCompanyReq): Promise<CreateCompanyRes>
  /**
   * 申请企业获取邮箱验证码
   * */
  sendApplyCreateCompanyEmailCode(data: { email: string }): Promise<CommApiRes>
  /**
   * 申请企业
   * */
  applyCreateCompany(data: ApplyCreateCompanyReq): Promise<CommApiRes>
  /**
   * 申请企业状态查询
   * */
  applyCreateCompanyStatusQuery(data: { applyReceiptCode: string }): Promise<CommApiRes>
  /**
   * 获取申请企业列表
   * */
  getApplyCreateCompanyList(data: CommGetDatReq): Promise<CommApiRes>
  /**
   * 审核企业申请
   * */
  applyCreateCompanyStatusAudit(data: ApplyCompanyStatusAuditReq): Promise<CommApiRes>
  /**
   * 获取版本列表
   * */
  getVersionList(data: GetVersionReq): Promise<GetVersionRes>
  /**
   * 创建版本
   * */
  createVersion(data: VersionReq): Promise<VersionRes>
  /**
   * 更新版本
   * */
  updateVersion(data: VersionReq): Promise<VersionRes>
  /**
   * 发布新版本
   * */
  releaseNewVersion(data: ReleaseNewVersionReq): Promise<ReleaseNewVersionRes>
  /**
   * 根据设置key获取平台系统设置
   * */
  getPlatformSystemSettingByKey(data: { key: string }): Promise<CommApiRes>
  /**
   * 获取权限列表
   * */
  getPermissionList(): Promise<GetPermissionListRes>
  /**
   * 创建或更新权限
   * */
  creatOrUpdatePermission(data: CreatOrUpdatePermissionReq): Promise<CreatOrUpdatePermissionRes>
  /**
   * 删除权限
   * */
  deletePermission(data: DeletePermissionReq): Promise<DeletePermissionRes>
}

export const PLATFORM_APIS: PlatformApis = {
  login(data) {
    return axiosServer.request({
      url: '/platform/login',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getUserInfo() {
    return axiosServer.request({
      url: '/platform/getUserInfo',
      method: 'get',
      showNotify: true
    })
  },
  getCompanyList(params) {
    return axiosServer.request({
      url: '/platform/getCompanyList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  createCompany(data) {
    return axiosServer.request({
      url: '/platform/createCompany',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateCompany(data) {
    return axiosServer.request({
      url: '/platform/updateCompany',
      method: 'post',
      data,
      showNotify: true
    })
  },
  sendApplyCreateCompanyEmailCode(data) {
    return axiosServer.request({
      url: '/platform/sendApplyCreateCompanyEmailCode',
      method: 'post',
      data,
      showNotify: true
    })
  },
  applyCreateCompany(data) {
    return axiosServer.request({
      url: '/platform/applyCreateCompany',
      method: 'post',
      data,
      showNotify: true
    })
  },
  applyCreateCompanyStatusQuery(params) {
    return axiosServer.request({
      url: '/platform/applyCreateCompanyStatusQuery',
      method: 'get',
      params,
      showNotify: true
    })
  },
  getApplyCreateCompanyList(params) {
    return axiosServer.request({
      url: '/platform/getApplyCreateCompanyList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  applyCreateCompanyStatusAudit(data) {
    return axiosServer.request({
      url: '/platform/applyCreateCompanyStatusAudit',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getVersionList(params) {
    return axiosServer.request({
      url: '/platform/getVersionList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  createVersion(data) {
    return axiosServer.request({
      url: '/platform/createVersion',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateVersion(data) {
    return axiosServer.request({
      url: '/platform/updateVersion',
      method: 'post',
      data,
      showNotify: true
    })
  },
  releaseNewVersion(data) {
    return axiosServer.request({
      url: '/platform/releaseNewVersion',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getPlatformSystemSettingByKey(params) {
    return axiosServer.request({
      url: '/platform/getPlatformSystemSettingByKey',
      method: 'get',
      params,
      showNotify: true
    })
  },
  getPermissionList() {
    return axiosServer.request({
      url: '/platform/getPermissionList',
      method: 'get',
      showNotify: true
    })
  },
  creatOrUpdatePermission(data) {
    return axiosServer.request({
      url: '/platform/creatOrUpdatePermission',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deletePermission(data) {
    return axiosServer.request({
      url: '/platform/deletePermission',
      method: 'post',
      data,
      showNotify: true
    })
  }
}
