import * as CryptoJS from 'crypto-js'
import _ from 'lodash'
import store from '@/store'
import globalConfig from '@/config/index'

import { COMMON_APIS } from '@/apis/common-apis'
import type { Router } from 'vue-router'
import type { UploadFileRes } from '@/types/apis-types/common-apis-types'

const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const assetsBaseUrl: string = globalConfig.assetsBaseUrl[env]
const aesConfig = globalConfig.aesConfig[env]

/**
 * @description: 获取storage
 * @param {string} key
 * @return {*}
 */
export function getStorage(key: string) {
  const storageData = localStorage.getItem(key)
  if (storageData) {
    try {
      const temp = JSON.parse(storageData)
      return temp.data
    } catch (error) {
      console.log(error)
    }
  } else {
    return undefined
  }
}

/**
 * @description: 设置storage
 * @param {string} key
 * @param {T} data
 * @return {*}
 */
export function setStorage<T>(key: string, data: T): void {
  localStorage.setItem(
    key,
    JSON.stringify({
      type: typeof data,
      data: data
    })
  )
}

/**
 * @description: 删除storage中的key
 * @param {string} key
 * @return {*}
 */
export function removeStorage(key: string | string[]): void {
  if (typeof key === 'string') {
    if (key === 'all') {
      localStorage.clear()
    } else {
      localStorage.removeItem(key)
    }
  } else if (Array.isArray(key)) {
    key.forEach(item => {
      localStorage.removeItem(item)
    })
  }
}

/**
 * @description: 获取token
 * @return {*}
 */
export function getToken(): string {
  return getStorage('token')
}

/**
 * @description: 设置token
 * @param {string} token
 * @return {*}
 */
export function setToken(token: string): void {
  setStorage('token', token)
}

/**
 * @description: 同步方法转异步执行
 * @param {function} callback
 * @return {*}
 */
export function asyncToAwait(callback: () => any) {
  setTimeout(() => {
    callback?.()
  }, 0)
}

/**
 * @description: 设置页面title
 * @param {string} title
 * @return {*}
 */
export function setTitle(title: string): void {
  document.title = title
}

/**
 * @description: 获取数组中从后往前寻找一个符合条件的元素的索引
 * @param {*} arr
 * @param {function} callback
 * @return {*}
 */
export function findLast(arr: [], callback: (item: any) => any) {
  // 先将数组反转
  const reversedArr = arr.slice().reverse()
  // 使用find找到第一个匹配元素
  const firstMatch = reversedArr.find(callback)
  if (firstMatch !== undefined) {
    // 如果找到了，返回在原数组中的索引
    const lastIndex = arr.lastIndexOf(firstMatch)
    return { element: firstMatch, index: lastIndex }
  }
  return null
}

/**
 * @description: 回到顶部
 * @param {*} isSmooth=true 使用平滑滚动
 * @return {*}
 */
export function scrollToTop(isSmooth = true) {
  if (isSmooth) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  } else {
    window.scrollTo(0, 0)
  }
}

/**
 * @description: 处理自建键盘点击的回调
 * @param {string} code
 * @param {string} num
 * @param {function} completeCallback
 * @param {function} clickNext
 * @return {*}
 */
export function keyboardCallback(code: string, num: string, completeCallback?: () => any, clickNext?: () => any) {
  if (code === 'complete') {
    completeCallback?.()
  } else {
    if (code === 'del') {
      if (num !== '') {
        num = num.slice(0, num.length - 1)
      }
    } else if (code === 'add10') {
      num = Number(num) + 10 + ''
    } else if (code === 'add100') {
      num = Number(num) + 100 + ''
    } else if (code === 'add500') {
      num = Number(num) + 500 + ''
    } else {
      num += code
    }
    setTimeout(() => {
      clickNext?.()
    }, 0)
  }
  return num
}

/**
 * @description: 为数字每隔3位添加一个逗号
 * @param {number} num
 * @return {*}
 */
export function addCommasToNumber(num: number | string) {
  // 先将数字转换为字符串
  const numberString = num.toString()
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * @description: 深拷贝对象
 * @param {any} obj
 * @return {*}
 */
export function deepClone(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj // 如果是原始数据类型或 null，则直接返回
  }

  if (Array.isArray(obj)) {
    // 如果是数组，创建一个新数组并递归复制每个元素
    const clonedArr = []
    for (let i = 0; i < obj.length; i++) {
      clonedArr[i] = deepClone(obj[i])
    }
    return clonedArr
  }

  // 如果是普通对象，创建一个新对象并递归复制每个属性
  const clonedObj: any = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone(obj[key])
    }
  }
  return clonedObj
}

/**
 * @description: 判断是否是空对象
 * @param {object} obj
 * @return boolean
 */
export function isEmptyObject(obj: object) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false // 如果对象有属性，则不为空
    }
  }
  return true // 如果对象没有属性，为空
}

/**
 * @description: 数组分组
 * @param {array} list
 * @param {string} props
 * @return Array
 */
export function groupedData(list: object[], props: string) {
  if (list && list.length) {
    return deepClone(list).reduce((groups: any, item: any) => {
      const groupKey = getPropertyValueByPath(item, props)
      if (!groups[groupKey]) {
        groups[groupKey] = []
      }
      groups[groupKey].push(item)
      return groups
    }, {})
  }
  return []
}

/**
 * @description: 根据'xxx.ccc.bbb'的形式获取object下的bbb的值
 * @description:如obj[xxx][ccc][bbb]
 * @param {any} obj
 * @param {string} path
 * @return {*}
 */
function getPropertyValueByPath(obj: any, path: string) {
  // 使用 split 方法将路径字符串分割成属性名称数组
  const pathArray = path.split('.')
  // 逐级遍历对象的属性，直到找到目标属性值
  let result = obj
  for (const prop of pathArray) {
    if (result && result.hasOwnProperty(prop)) {
      result = result[prop]
    } else {
      return undefined // 如果属性不存在，则返回 undefined
    }
  }

  return result
}

/**
 * @description: 对象数组排序
 * @param {array} array 数组
 * @param {string} key 需要比较多元素的key
 * @param {string} type 升序/降序 asce/desc
 * @return Array
 */
export const sortObjectArray = (array: [], key: string, type: string) => {
  if (type === 'desc') {
    // 降序
    return array.sort((a, b) => b[key] - a[key])
  } else if (type === 'asce') {
    // 升序
    return array.sort((a, b) => a[key] - b[key])
  }
}

/**
 * @description: 计算时间戳是否是今天，否则返回时间戳对应的日期和时间
 * @verify: 是否需要验证是今天 true/false
 * @return {*}
 */
export const verifyTimestampAsToDay = (timestamp: number, verify = false) => {
  const currentDate = new Date()
  const inputDate = new Date(timestamp)
  if (verify) {
    // 检查时间戳是否是今天
    if (
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
      // 是今天
      const hours = inputDate.getHours()
      const minutes = inputDate.getMinutes()
      return `今天 ${hours}:${minutes < 10 ? '0' : ''}${minutes}`
    } else {
      // 不是今天
      const year = inputDate.getFullYear()
      const month = String(inputDate.getMonth() + 1).padStart(2, '0')
      const day = String(inputDate.getDate()).padStart(2, '0')
      const hours = String(inputDate.getHours()).padStart(2, '0')
      const minutes = String(inputDate.getMinutes()).padStart(2, '0')
      return `${year}/${month}/${day} ${hours}:${minutes}`
    }
  } else {
    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')
    const hours = String(inputDate.getHours()).padStart(2, '0')
    const minutes = String(inputDate.getMinutes()).padStart(2, '0')
    return `${year}/${month}/${day} ${hours}:${minutes}`
  }
}

/**
 * @description: 检查是否是有效的数字 - 整数, 浮点数
 * @return {*}
 */
export const isNumberExists = (value: any) => {
  return value !== undefined && value !== null && typeof value === 'number'
}

/**
 * @description: 获取用户设备类型
 * @return {*}
 */
export const getDeviceType = () => {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent
  // 使用正则表达式检查用户代理字符串以确定设备类型
  if (/Android/i.test(userAgent)) {
    return 'Android'
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'ios'
  } else if (/Windows Phone|iemobile|WPDesktop/i.test(userAgent)) {
    return 'Windows Phone'
  } else if (/BlackBerry/i.test(userAgent)) {
    return 'BlackBerry'
  } else if (/Macintosh|MacIntel|MacPPC|MacIntelMac/i.test(userAgent)) {
    return 'Mac'
  } else if (/Windows|Win32|Win64/i.test(userAgent)) {
    return 'Windows'
  } else if (/Linux/i.test(userAgent)) {
    return 'Linux'
  } else {
    return 'unknown'
  }
}
/**
 * @description: 获取地址栏参数的值
 * @param {string} key
 * @return {string} value
 */
export const getQueryParameter = (key: string): string | null => {
  // 获取地址栏中的查询参数部分（包括问号）
  const queryString = window.location.search
  // 去掉问号，并将参数以键值对的形式存储在params对象中
  const params = new URLSearchParams(queryString)
  // 使用get()方法获取特定参数的值
  return params.get(key)
}

/**
 * get random number between start to end
 * @param {*} start
 * @param {*} end
 * @returns
 */
export function getRandom(start: number, end: number): number {
  return Math.floor(Math.random() * (end - start) + start)
}

/**
 * @description: 退出登录 需要删除一些缓存信息
 * @token: token
 * @currentUserInfo: 用户信息
 * @return {*}
 */
export const logout = (router: Router) => {
  if (router.currentRoute.value.name === 'client') {
    window.location.reload()
  } else {
    globalConfig.titlePrefix = undefined
    removeStorage(['companySettingInfo', 'userSettingInfo'])
    setTimeout(() => {
      router.replace({
        name: 'login'
      })
    }, 5)
  }
  removeStorage(['currentSystemType', 'token', 'isDarkMode'])
  store.commit('setToken', null)
  // removeStorage(['currentUserInfo'])
  // store.commit('setCurrentUserInfo', null)
  cutSkin('light')
}

/**
 * @description: 上传文件
 * @file: 文件
 * @headers: 自定义头
 * @return {*}
 */
export const uploadFile = async (file: File, headers: any) => {
  const uploadFileRes: UploadFileRes = await COMMON_APIS.uploadFile(
    {
      file: file
    },
    headers
  )
  return uploadFileRes
}

/**
 * @description: 上传文件到tg图床
 * @file: 文件
 * @headers: 自定义头
 * @return {*}
 */
export const uploadFileToTg = async (file: File, headers?: any) => {
  const uploadFileRes: UploadFileRes = await COMMON_APIS.uploadFileToTelegram(
    {
      file: file
    },
    headers ?? {}
  )
  return uploadFileRes
}

// 处理媒体文件的url，区分本地bold类型和线上的string类型
export const disposeMediaUrl = (url: string) => {
  if (url.indexOf('/upload/') !== -1) {
    url = assetsBaseUrl + url
  }
  return url
}

// 查找父级元素
export const findParentWithClass = (event: any, className: string) => {
  const target = event.target
  // 要查找的类名
  const classNameToFind = className
  // 循环向上查找父级元素，直到找到具有指定类名的父级元素
  let currentElement = target
  while (currentElement !== null) {
    if (currentElement.classList.contains(classNameToFind)) {
      // 找到了具有指定类名的父级元素
      console.log('Found parent with class:', classNameToFind)
      return currentElement
      break
    }
    // 继续向上查找
    currentElement = currentElement.parentElement
  }
}

// 修改页面亮度
export const adjustBrightness = (value: number) => {
  document.body.style.filter = `brightness(${value}%)`
}

// 防抖函数
// 在连续的操作中，无论进行了多长时间，只有某一次的操作后在指定的时间内没有再操作，这一次才被判定有效
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>
  return function (this: typeof window, ...args: Parameters<T>): void {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

// 节流函数
// 规定时间内，只触发一次
export const throttle = <T extends (...args: any[]) => void>(func: T, wait: number) => {
  let previous = 0

  return function (this: typeof window, ...args: any[]) {
    const now = Date.now()
    if (now - previous >= wait) {
      func.apply(this, args)
      previous = now
    }
  }
}

// 进入全屏模式
export const toggleFullscreen = () => {
  const element = document.documentElement as Element // 获取整个文档的元素
  if (
    document.fullscreenElement ||
    (document as any).webkitFullscreenElement ||
    (document as any).mozFullScreenElement ||
    (document as any).msFullscreenElement
  ) {
    // 如果已经是全屏状态，则退出全屏
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if ((document as any).webkitExitFullscreen) {
      ;(document as any).webkitExitFullscreen()
    } else if ((document as any).mozCancelFullScreen) {
      ;(document as any).mozCancelFullScreen()
    } else if ((document as any).msExitFullscreen) {
      ;(document as any).msExitFullscreen()
    }
  } else {
    // 否则，进入全屏
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if ((element as any).webkitRequestFullscreen) {
      ;(element as any).webkitRequestFullscreen()
    } else if ((element as any).mozRequestFullScreen) {
      ;(element as any).mozRequestFullScreen()
    } else if ((element as any).msRequestFullscreen) {
      ;(element as any).msRequestFullscreen()
    }
  }
}

// 切换皮肤
export const cutSkin = (theme: string) => {
  console.log('theme:', theme)

  if (theme === 'dark') {
    setStorage('isDarkMode', true)
  } else {
    setStorage('isDarkMode', false)
  }

  const classList = document.documentElement.classList
  const temp: [string?] = []
  classList.forEach(item => {
    if (item.indexOf('theme-') === -1) {
      temp.push(item)
    }
  })
  const s: string = temp.join(' ')
  const importEndThemeList = store.getters['_getImportEndThemeList']
  if (importEndThemeList.indexOf(theme) === -1) {
    import(`@/assets/style/skin/${theme}.less`).then(() => {
      document.documentElement.className = `theme-${theme} ${s}`
      importEndThemeList.push(theme)
      store.commit('setImportEndThemeList', importEndThemeList)
    })
  } else {
    document.documentElement.className = `theme-${theme} ${s}`
  }
}

// // 加密数据
// export const encryptJson = (jsonData: any, key: string): string | null => {
//   try {
//     // 将 JSON 数据转换为字符串
//     const jsonString = JSON.stringify(jsonData)

//     // 使用 AES-128-ECB 加密
//     const encryptedString = CryptoJS.AES.encrypt(jsonString, key, {
//       mode: CryptoJS.mode.ECB,
//       padding: CryptoJS.pad.Pkcs7
//     }).toString()

//     return encryptedString
//   } catch (e) {
//     console.error('加密出错：', e)
//     return null
//   }
// }
// // 解密数据
// export const decryptAES = (encryptedText: string, key: string): any | null => {
//   try {
//     // 使用 AES-128-ECB 解密
//     const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key, {
//       mode: CryptoJS.mode.ECB,
//       padding: CryptoJS.pad.Pkcs7
//     })
//     const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8)

//     // 将解密结果转换为 JSON 对象
//     const decryptedData = JSON.parse(decryptedString)

//     return decryptedData
//   } catch (e) {
//     console.error('解密出错：', e)
//     return null
//   }
// }

// 加密函数
export const encryptAES = (data: any, key: string, iv: string): string | null => {
  try {
    const jsonString = JSON.stringify(data)
    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC, // 指定使用 CBC 模式
      padding: CryptoJS.pad.Pkcs7, // 使用 PKCS7 填充
      keySize: 256 // 使用 256 位密钥
    })
    return encrypted.toString()
  } catch (error) {
    console.log('加密出错：', error)
    return null
  }
}

// 解密数据
export const decryptAES = (encryptedText: string, key?: string): any | null => {
  try {
    const ivIndex = Number(encryptedText.slice(0, 1))
    const encryptedStr = encryptedText.slice(1)
    const iv = encryptedStr.slice(ivIndex, 32 + ivIndex)
    const realEncryptedText = removeCharactersAfterIndex(encryptedStr, ivIndex, 32)
    // const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key, {
    const decryptedBytes = CryptoJS.AES.decrypt(realEncryptedText, key || (aesConfig.postSecretKey as string), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC, // 指定使用 CBC 模式
      padding: CryptoJS.pad.Pkcs7, // 使用 PKCS7 填充
      keySize: 256 // 使用 256 位密钥
    })
    const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8)
    // 将解密结果转换为 JSON 对象
    const decryptedData = JSON.parse(decryptedString)
    return decryptedData
  } catch (e) {
    console.error('解密出错：', e)
    return null
  }
}

// 生成随机 IV
export const generateRandomIV = () => {
  return CryptoJS.lib.WordArray.random(16).toString()
}

export const getRandomDigitFromString = (str: string): any => {
  // 随机获取字符串中的一个字符
  const randomChar = str.charAt(Math.floor(Math.random() * str.length))

  // 判断字符是否是数字
  if (/\d/.test(randomChar)) {
    // 如果是数字则返回该字符
    return randomChar
  } else {
    // 如果不是数字，则递归调用函数，直到找到数字为止
    return getRandomDigitFromString(str)
  }
}

// 将IV插入到指定索引位置
export const insertIVIntoCipherText = (cipherText: string, iv: string, index: number): string => {
  const modifiedCipherText = cipherText.slice(0, index) + iv + cipherText.slice(index)
  return index + modifiedCipherText
}

// 字符串切片
export const removeCharactersAfterIndex = (inputString: string, index: number, length: number): string => {
  // 删除指定索引及其后的字符
  const modifiedString = inputString.slice(0, index) + inputString.slice(index + length)
  return modifiedString
}

// 将所有加密数据的流程封装到一个公共函数里
export const encipherString = (sendData: any, postSecretKey?: string): string => {
  // 创建iv
  const randomIV = generateRandomIV()
  // console.log('randomIV:', randomIV)
  // 创建iv随机插入索引
  const randomIndex = Number(getRandomDigitFromString(randomIV))
  // console.log('getRandomDigitFromString:', randomIndex)
  // 创建密文
  const encryptedTextTemp = encryptAES(sendData, postSecretKey || (aesConfig.postSecretKey as string), randomIV)
  // console.log('encryptedTextTemp Text:', encryptedTextTemp)
  // 插入iv到密文中
  const encryptedText = insertIVIntoCipherText(encryptedTextTemp as string, randomIV, randomIndex)
  // console.log('encryptedText Text:', encryptedText)
  return encryptedText
}
// 秒数转成几小时几分钟几秒钟的格式
export const secondsFormatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let formattedTime = ''
  if (hours > 0) {
    formattedTime += hours + '小时'
  }
  if (minutes > 0) {
    formattedTime += minutes + '分钟'
  }
  if (remainingSeconds > 0) {
    formattedTime += remainingSeconds + '秒'
  }

  return formattedTime.trim() // 去除末尾可能的空格
}
// 将数组中的元素挪动到首位
export const moveObjectToFront = (arr: [], key: string, value: any) => {
  const index = arr.findIndex(obj => obj[key] === value)
  if (index !== -1) {
    const obj = arr.splice(index, 1)[0] // 从原位置删除
    arr.unshift(obj) // 添加到数组开头
  }
  return arr
}

// 使用正则表达式匹配空格或空白字符
export const isOnlyWhitespace = (str: string) => {
  return /^\s*$/.test(str)
}

// 获取路由的标题
export const showTitle = (item: any, $t?: any) => {
  let title = ''

  if (globalConfig.useI18n && $t) {
    title = $t(item.name)
  } else {
    title = (item.meta && item.meta.title) || item.name
  }
  return title
}

// 得到两个数组的并集, 两个数组的元素为数值或字符串
export const getUnion = (arr1: any[], arr2: any[]) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

export const findNodeUpperByClasses: any = (ele: any, classes: string[]) => {
  if (ele.parentNode) {
    const parentNode = ele.parentNode
    const classList = parentNode.classList
    if (classList && classes.every(className => classList.contains(className))) {
      return parentNode
    } else {
      return findNodeUpperByClasses(parentNode, classes)
    }
  } else {
    return null
  }
}

export const forEach = (arr: any[], fn: (a: any, b: any, c: any) => any) => {
  if (!arr.length || !fn) return
  let i = -1
  const len = arr.length
  while (++i < len) {
    const item = arr[i]
    fn(item, i, arr)
  }
}

export const hasChild = (item: any) => {
  return item.children && item.children.length !== 0
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr: any[], arr: any[]) => {
  return targetarr.some((_: any) => arr.indexOf(_) > -1)
}

const showThisMenuEle = (item: any, access: any) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true
    else return false
  } else return true
}

/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list: any[], access: any[]) => {
  const res: any[] = []
  forEach(list, item => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      const obj: any = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: item.meta
      }
      if ((hasChild(item) || (item.meta && item.meta.showAlways)) && showThisMenuEle(item, access)) {
        obj.children = getMenuByRouter(item.children, access)
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href
      if (showThisMenuEle(item, access)) res.push(obj)
    }
  })
  return res
}

/**
 * @param {Array} matched 当前路由的matched列表
 * @returns {Array}
 */
export const getBreadcrumbByRoute = (matched: any[]) => {
  if (matched && matched.length) {
    return matched.map((item: any) => {
      return item.meta.title
    })
  }
  return []
}

/**
 * 获取对应页面的高度
 * @param {string} className 页面的class名称,需配合页面设置高度为百分百
 * @returns {number}
 */
export const getPageDomHeight = (className: string) => {
  const pageDom: HTMLElement = document.querySelector(className) as HTMLElement
  if (pageDom) {
    return pageDom.offsetHeight
  }
  return 0
}

/**
 * @description: 获取指定长度的随机字符串
 * @param {number} length
 * @return {*}
 */
export const generateRandomString = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomChars = _.sampleSize(characters, length)
  return randomChars.join('')
}
