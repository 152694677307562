<template>
  <span class="my-icon" :class="iconClass" :style="iconStyle" @click="handleClick"></span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { PropType } from 'vue'
const emit = defineEmits(['click'])

const props = defineProps({
  prefix: {
    type: String,
    default: 'iconfont'
  },
  name: {
    type: String,
    default: ''
  },
  size: {
    type: [Number, String],
    default: 14
  },
  color: {
    type: String,
    default: '#000'
  },
  customClass: {
    type: Array as PropType<string[]>,
    default: () => {
      return []
    }
  }
})

const handleClick = (event: Event) => {
  emit('click', event)
}
const iconClass = computed(() => {
  return [props.prefix, props.name, ...props.customClass]
})

const iconStyle = computed(() => {
  return {
    color: props.color,
    'font-size': typeof props.size === 'number' ? props.size + 'px' : props.size
  }
})
</script>

<style lang="less" scoped>
.my-icon {
  font-size: 14px;
}
</style>
