import HttpRequest from '@/libs/http-instance'
import globalConfig from '@/config/index'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const baseUrl: string = globalConfig.baseUrl[env]
const axiosServer: HttpRequest = new HttpRequest(baseUrl)
import type {
  CreateGuestAccountReq,
  CreateGuestAccountRes,
  GetThirdPartyUserInfoReq,
  GetThirdPartyUserInfoRes
} from '@/types/apis-types/visitor-apis-types'
interface VisitorApis {
  /**
   * 创建访客用户账号
   * */
  createGuestAccount(data: CreateGuestAccountReq): Promise<CreateGuestAccountRes>
  /**
   * 获取第三方平台的用户信息
   * */
  getThirdPartyUserInfo(data: GetThirdPartyUserInfoReq): Promise<GetThirdPartyUserInfoRes>
}

export const VISITOR_APIS: VisitorApis = {
  createGuestAccount(data) {
    return axiosServer.request({
      url: '/visitor/createGuestAccount',
      method: 'post',
      data
    })
  },
  getThirdPartyUserInfo(data) {
    return axiosServer.request({
      url: '/visitor/getThirdPartyUserInfo',
      method: 'post',
      data
    })
  }
}
