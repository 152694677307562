/**
 * @description: 客服用户设置
 * @return {*}
 */

export const SERVER_USER_SETTING_KEY = {
  quickReply: {
    name: '快捷回复',
    desc: '客服用户设置的快捷回复',
    key: 'QUICK_REPLY'
  },
  uiSetting: {
    name: '界面设置',
    desc: '客服用户界面的设置：如字体，亮度，后续会增加更多',
    key: 'SERVER_USER_UI_SETTING'
  }
}

/**
 * @description: 企业设置
 * @return {*}
 */

export const COMPANY_SETTING_KEY = {
  otherSetting: {
    name: '其他设置',
    desc: '用于企业的一些其他设置',
    key: 'OTHER_SETTING'
  }
}

/**
 * @description: 平台设置
 * @return {*}
 */

export const PLATFORM_SETTING_KEY = {
  currentVersion: {
    name: '获取当前版本号',
    desc: '获取当前版本号',
    key: 'CURRENT_VERSION'
  }
}

/**
 * @description: 翻译支持的语言对应map
 * @return {*}
 */
export const FRANC_LANGUAGE_CODE_TO_DEEP_CODE: any = {
  cmn: 'ZH',
  eng: 'EN'
}
