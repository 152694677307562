import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import homeView from '@/views/homeView.vue'
import platformMain from '@/views/platformView.vue'
import HttpRequest from '@/libs/http-instance'
import { setTitle, getToken, getStorage, removeStorage } from '@/libs/utils'
import globalConfig from '@/config/index'
import { COMPANY_SETTING_KEY } from '@/constants/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Welcome'
    },
    component: homeView
  },
  {
    path: '/client',
    name: 'client',
    meta: {
      title: `已连线`
    },
    component: () => import('@/views/clientView.vue')
  },
  {
    path: '/server',
    name: 'server',
    meta: {
      title: `服务中`
    },
    component: () => import('@/views/serverView.vue'),
    children: [
      {
        path: 'serverUserSettingView',
        name: 'serverUserSettingView',
        meta: {
          title: `个人设置`
        },
        component: () => import('@/views/server/serverUserSettingView.vue')
      },
      {
        path: '/server/appListView',
        name: 'appListView',
        meta: {
          title: `应用列表`
        },
        component: () => import('@/views/server/appListView.vue'),
        children: [
          {
            path: 'serverTypeManage',
            name: 'serverTypeManage',
            meta: {
              title: `客服类型管理`
            },
            component: () => import('@/views/server/serverTypeManageView.vue')
          },
          {
            path: 'serverAccountManage',
            name: 'serverAccountManage',
            meta: {
              title: `客服管理`
            },
            component: () => import('@/views/server/serverAccountManageView.vue')
          },
          {
            path: 'serverRoleManage',
            name: 'serverRoleManage',
            meta: {
              title: `角色管理`
            },
            component: () => import('@/views/server/serverRoleManageView.vue')
          },
          {
            path: 'serverAuthManage',
            name: 'serverAuthManage',
            meta: {
              title: `应用管理`
            },
            component: () => import('@/views/server/serverAuthManageView.vue')
          },
          {
            path: 'serverSystemSetting',
            name: 'serverSystemSetting',
            meta: {
              title: `系统设置`
            },
            component: () => import('@/views/server/serverSystemSettingView.vue')
          },
          {
            path: 'landingPageManage',
            name: 'landingPageManage',
            meta: {
              title: `文宣管理`
            },
            component: () => import('@/views/server/landingPageManageView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/platform',
    name: 'platform',
    meta: {
      title: '平台管理'
    },
    component: platformMain,
    redirect: '/platform/platform-home',
    children: [
      {
        path: 'platform-home',
        name: 'platformHome',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/platform/homeView.vue')
      },
      {
        path: 'platform-setting',
        name: 'platformSetting',
        meta: {
          title: '平台设置'
        },
        component: () => import('@/views/platform/settingView.vue')
      }
    ]
  },
  {
    path: '/platform-menu',
    name: 'platformMenu',
    meta: {
      title: '平台管理',
      leftMenu: true
    },
    component: platformMain,
    children: [
      {
        path: 'company-manage',
        name: 'companyManage',
        meta: {
          title: '企业管理',
          icon: '_icon-qiyexinxi'
        },
        children: [
          {
            path: 'company-list',
            name: 'company-list',
            meta: {
              title: '企业列表'
            },
            component: () => import('@/views/platform/companyList.vue')
          },
          {
            path: 'company-apply-list',
            name: 'companyApplyList',
            meta: {
              title: '申请列表'
            },
            component: () => import('@/views/platform/companyApplyList.vue')
          }
        ]
      },
      {
        path: '/platform-system-manage',
        name: 'platformSystemManage',
        meta: {
          title: '系统管理',
          icon: '_icon-qiyexinxi'
        },
        children: [
          {
            path: 'platformAuthManage',
            name: 'platformAuthManage',
            meta: {
              title: `应用管理`
            },
            component: () => import('@/views/platform/authManageView.vue')
          },
          {
            path: 'platform-system-setting',
            name: 'platformSystemSetting',
            meta: {
              title: `系统设置`
            },
            component: () => import('@/views/platform/authManageView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: `登录`
    },
    component: () => import('@/views/loginView.vue')
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('@/views/refreshView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let titlePrefix = null
  if (globalConfig.titlePrefix) {
    titlePrefix = globalConfig.titlePrefix
  } else if (
    getStorage('companySettingInfo') &&
    getStorage('companySettingInfo')[COMPANY_SETTING_KEY.otherSetting.key]['systemName']
  ) {
    titlePrefix = getStorage('companySettingInfo')[COMPANY_SETTING_KEY.otherSetting.key]['systemName']
  }

  setTitle((titlePrefix ? titlePrefix + '-' : '') + (to.meta.title as string))
  const token = getToken()
  const currentSystemType = getStorage('currentSystemType')

  if (token && (to.name === 'login' || to.name === 'home')) {
    if (currentSystemType === 1) {
      next({
        name: 'server'
      })
    } else if (currentSystemType === 2) {
      next({
        name: 'platform'
      })
    } else {
      removeStorage(['isDarkMode', 'userSettingInfo', 'companySettingInfo', 'token'])
      next()
    }
  } else if (!token && to.name !== 'login' && to.name !== 'home' && to.name !== 'client') {
    next({
      name: 'login'
    })
  } else {
    next()
  }
})

HttpRequest.setRouter(router)

export default router
