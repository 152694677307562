<template>
  <div class="platform-page">
    <div class="layout">
      <Layout>
        <Header class="flex al-i-c ju-c-sb" :style="{ background: theme === 'dark' ? '#181820' : '#fff' }">
          <div class="left-view flex al-i-c">
            <div class="layout-logo" :class="{ collapsed: isCollapsed }">
              <img v-if="isCollapsed" class="logo-img" src="@/assets/images/logo-min.png" alt="" />
              <img v-if="!isCollapsed" class="logo-img" src="@/assets/images/logo-max.png" alt="" />
            </div>
            <div class="flex al-i-c m-l-16">
              <MyIcon
                :style="{ margin: '0 14px 0 0' }"
                :size="20"
                :name="isCollapsed ? 'icon-zhedie-right' : 'icon-zhedie-left'"
                @click="collapsedSider"
              />
              <Breadcrumb>
                <BreadcrumbItem v-for="(item, index) in currentBreadcrumb" :key="index">{{ item }}</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>

          <div class="layout-nav flex al-i-c m-r-10">
            <router-link :to="{ name: 'platformHome' }">
              <div class="nav-item">
                <Icon type="md-home"></Icon>
                <span class="m-l-5">首页</span>
              </div>
            </router-link>
            <div class="nav-item">
              <Dropdown>
                <div>
                  <Icon type="md-globe" />
                  <span class="m-l-5">语言</span>
                </div>
                <template #list>
                  <DropdownMenu>
                    <DropdownItem>简体中文</DropdownItem>
                    <DropdownItem>English</DropdownItem>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </div>
            <div class="nav-item">
              <Icon type="md-moon"></Icon>
              <span class="m-l-5">夜间</span>
            </div>
            <router-link :to="{ name: 'platformSetting' }">
              <div class="nav-item">
                <Icon type="md-cog" />
                <span class="m-l-5">设置</span>
              </div>
            </router-link>
            <div class="user-info-view flex al-i-c">
              <Dropdown @on-click="dropdownClick">
                <img class="user-avatar" :src="currentUserInfo.avatar" alt="" />
                <template #list>
                  <DropdownMenu>
                    <DropdownItem name="individualCenter">{{ currentUserInfo.nickName }}</DropdownItem>
                    <DropdownItem name="logout">登出</DropdownItem>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider
            v-model="isCollapsed"
            class="custom-scrollbar"
            collapsible
            hide-trigger
            :collapsed-width="80"
            :style="{ background: theme === 'dark' ? '#181820' : '#fff' }"
          >
            <side-menu
              ref="sideMenu"
              :active-name="(currentRoute.name as string)"
              :theme="theme"
              :collapsed="isCollapsed"
              :menu-list="menuList"
              @on-select="turnToPage"
            />
          </Sider>
          <Layout :style="{ padding: '16px' }">
            <Content
              class="router-view-content"
              :style="{ padding: '16px', borderRadius: '6px', backgroundColor: '#fff' }"
            >
              <router-view></router-view>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getMenuByRouter, getBreadcrumbByRoute, logout } from '@/libs/utils'
import myRouter from '@/router/index'
import SideMenu from '@/components/side-menu/side-menu.vue'
import MyIcon from '@/components/my-icon.vue'

import store from '@/store'
const router = useRouter()
const currentRoute = useRoute()
const isCollapsed = ref(false)
const theme = ref('light')
const menuList = ref()

const currentUserInfo = computed(() => {
  return store.getters['_getCurrentUserInfo']
})
const currentBreadcrumb = computed(() => {
  return getBreadcrumbByRoute(currentRoute.matched)
})

const turnToPage = (route: any) => {
  let { name, params, query }: any = {}
  if (typeof route === 'string') name = route
  else {
    name = route.name
    params = route.params
    query = route.query
  }
  if (name.indexOf('isTurnByHref_') > -1) {
    window.open(name.split('_')[1])
    return
  }
  router.push({
    name,
    params,
    query
  })
}

const collapsedSider = () => {
  isCollapsed.value = !isCollapsed.value
}

const dropdownClick = (name: string) => {
  console.log(name)
  if (name === 'logout') {
    logout(router)
  }
}

onMounted(() => {
  // 生成菜单
  let routeList: any[] = []
  let tempRouteList = myRouter.options.routes.filter(
    (item: any) => item.meta && item.meta.hasOwnProperty('leftMenu') && item.meta.leftMenu === true
  )
  tempRouteList.forEach((item: any) => {
    routeList.push(...item.children)
  })
  menuList.value = getMenuByRouter(routeList, [])
})
</script>

<style lang="less" scoped>
.platform-page {
  height: 100vh;
  .layout {
    height: 100vh;
    background-color: #f5f7f9;
    position: relative;
    overflow: hidden;
  }

  .ivu-layout-header {
    padding: 0;
    box-shadow: 0 4px 10px #dcdcdc;
    position: relative;
    z-index: 100;
    .ivu-menu-horizontal {
      height: 100%;
      line-height: unset;
    }

    .layout-logo {
      width: 160px;
      height: 30px;
      margin: 0 20px;
      line-height: 30px;
      background-color: #5b6270;
      border-radius: 3px;
      color: #fff;
      text-align: center;
      overflow: hidden;
      &.collapsed {
        width: 60px;
        margin: 0 10px;
      }
      .logo-img {
        width: 100%;
        display: block;
      }
    }
    .layout-nav {
      margin: 0 auto;
      .nav-item {
        padding: 0 10px;
        color: #515a6e;
        cursor: pointer;
      }
    }
    .user-info-view {
      height: 64px;
      margin-right: 20px;
      .user-avatar {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 40px;
        border: 1px solid #ddd;
      }
    }
  }
  .menu-icon {
    transition: all 0.3s;
  }
  .rotate-icon {
    transform: rotate(-180deg);
  }
  .ivu-layout-has-sider {
    height: calc(100vh - 66px);
    margin-top: 2px;
    .ivu-layout-sider {
      overflow: auto;
    }

    .router-view-content {
      height: calc(100vh - 66px - 32px);
    }
  }
}
</style>
