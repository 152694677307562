<template>
  <van-image-preview
    ref="imagePreviewRef"
    v-model:show="_isShowModal"
    class-name="my-media-preview"
    :start-position="currentIndex"
    :show-indicators="true"
    :images="_urls"
    :closeable="true"
    :close-on-click-image="false"
    @change="onChange"
  >
    <template #image="{ src }">
      <img v-if="src.indexOf('?type=image') !== -1" class="img" :src="src.replace('?type=image', '')" />
      <video
        v-if="src.indexOf('?type=video') !== -1"
        class="video"
        controls
        autoplay
        :src="src.replace('?type=video', '')"
      ></video>
    </template>
    <template #index="indexInfo">
      <div class="index-view">
        <div class="index-label-view">{{ indexInfo.index + 1 }}/{{ _urls.length }}</div>
        <div class="cut-button-view">
          <Button class="left-btn" shape="circle" icon="ios-arrow-dropleft" @click="pre"></Button>
          <Button class="right-btn" shape="circle" icon="ios-arrow-dropright" @click="next"></Button>
        </div>
        <div v-if="footerMenu.length" class="footer-view">
          <Button v-if="footerMenu.indexOf('send') !== -1" type="info" @click="footerMenuCallback('send')">发送</Button>
          <Button v-if="footerMenu.indexOf('edit') !== -1" type="info">编辑</Button>
        </div>
      </div>
    </template>
  </van-image-preview>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type { PropType } from 'vue'
import type { ImagePreviewInstance } from 'vant'
import type { MediaPreviewItem } from '@/types/event-types'
import { disposeMediaUrl } from '@/libs/utils'
import { EventBus } from '@/eventBus'

const props = defineProps({
  isShow: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  urls: {
    type: Array as PropType<MediaPreviewItem[]>,
    default: () => {
      return []
    }
  },
  files: {
    type: Array,
    default: () => {
      return []
    }
  },
  currentIndex: {
    type: Number,
    default: () => {
      return 0
    }
  },
  // 底部菜单按钮可选项：send-发送 ｜ edit-编辑
  // ['send', 'edit']
  footerMenu: {
    type: Array,
    default: () => {
      return []
    }
  }
})
const emit = defineEmits(['update:isShow'])

const imagePreviewRef = ref<ImagePreviewInstance>()

const _isShowModal = computed({
  get() {
    return props.isShow
  },
  set(val) {
    emit('update:isShow', val)
  }
})

const cutIndex = ref(props.currentIndex)

const _urls = computed({
  get() {
    return props.urls.map((item: MediaPreviewItem, index: number) => {
      //  item.url.indexOf('/upload/') !== -1 ?
      return disposeMediaUrl(item.url) + '?type=' + item.type
    })
  },
  set(val) {
    console.log('val:', val)
  }
})

// 方法部分
const onChange = (newIndex: number) => {
  cutIndex.value = newIndex
}
const footerMenuCallback = (type: string) => {
  if (type === 'send') {
    EventBus.emit('MEDIA_PREVIEW_SEND')
  }
}
const pre = () => {
  console.log('cutIndex:', cutIndex.value)

  imagePreviewRef.value?.swipeTo(cutIndex.value - 1)
}
const next = () => {
  console.log('cutIndex:', cutIndex.value)
  imagePreviewRef.value?.swipeTo(cutIndex.value + 1)
}
</script>

<style lang="less">
// .my-media-preview {
//   .ivu-modal-content {
//     background-color: #000;
//     .ivu-modal-body {
//       padding: 0;
//       overflow: hidden;
//     }
//     .ivu-modal-footer {
//       border-top: 0;
//     }
//   }

//   .carousel-el {
//     width: 100vw;
//     height: 100vh;
//   }

//   .carousel-item {
//     width: 80vw;
//     height: 100vh;
//     overflow: hidden;
//     margin: 0 auto;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .img,
//     .video {
//       max-width: 100%;
//       max-height: 100%;
//       display: block;
//     }
//   }
// }
.my-media-preview {
  .img,
  .video {
    max-width: 100%;
    max-height: 100vh;
    display: block;
    margin: 0 auto;
  }

  .van-image-preview__index {
    top: 0;
    left: 0;
    transform: unset;
    .index-label-view {
      position: fixed;
      left: 50%;
      top: var(--van-padding-md);
      transform: translateX(-50%);
    }
    .cut-button-view {
      position: fixed;
      .left-btn,
      .right-btn {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
      }
      .left-btn {
        left: 5vw;
      }
      .right-btn {
        right: 5vw;
      }
    }
    .footer-view {
      position: fixed;
      bottom: 5vh;
      right: 5vw;
    }
  }
}
</style>
