<template>
  <div class="side-menu-wrapper">
    <slot></slot>
    <Menu
      v-show="!collapsed"
      ref="menuRef"
      :active-name="activeName"
      :open-names="openedNames"
      :accordion="accordion"
      :theme="theme"
      width="auto"
      @on-select="handleSelect"
    >
      <template v-for="item in menuList">
        <template v-if="item.children && item.children.length === 1">
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :icon-size="iconSize"
            :parent-item="item"
          />
          <menu-item v-else :key="`menu-${item.children[0].name}`" :name="getNameOrHref(item, true)">
            <common-icon :size="iconSize" :type="item.children[0].icon || ''" />
            <span class="m-l-10">{{ showTitle(item.children[0]) }}</span>
          </menu-item>
        </template>
        <template v-else>
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :icon-size="iconSize"
            :parent-item="item"
          />
          <template v-else>
            <menu-item :key="`menu-${item.name}`" :name="getNameOrHref(item)">
              <common-icon :size="iconSize" :type="item.icon || ''" />
              <span class="m-l-10">{{ showTitle(item) }}</span>
            </menu-item>
          </template>
        </template>
      </template>
    </Menu>
    <div v-show="collapsed" class="menu-collapsed">
      <template v-for="item in menuList">
        <collapsed-menu
          v-if="item.children && item.children.length > 1"
          :key="`drop-menu-${item.name}`"
          hide-title
          :root-icon-size="rootIconSize"
          :icon-size="iconSize"
          :theme="theme"
          :parent-item="item"
          @on-click="handleSelect"
        />
        <template v-else>
          <Tooltip
            :key="`drop-menu-${item.name}`"
            transfer
            :content="showTitle(item.children && item.children[0] ? item.children[0] : item)"
            placement="right"
          >
            <a class="drop-menu-a" :style="{ textAlign: 'center' }" @click="handleSelect(getNameOrHref(item, true))">
              <common-icon
                :size="rootIconSize"
                :color="textColor"
                :type="item.icon || (item.children && item.children[0].icon)"
              />
            </a>
          </Tooltip>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'

import type { Ref, PropType } from 'vue'

import SideMenuItem from './side-menu-item.vue'
import CollapsedMenu from './collapsed-menu.vue'
import CommonIcon from '../common-icon/common-icon.vue'

import { getUnion, showTitle } from '@/libs/utils'

const currentRoute = useRoute()

const emit = defineEmits(['on-select'])

const props = defineProps({
  menuList: {
    type: Array as PropType<any[]>,
    default: () => {
      return []
    }
  },
  collapsed: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String,
    default: ''
  },
  rootIconSize: {
    type: Number,
    default: 20
  },
  iconSize: {
    type: Number,
    default: 16
  },
  accordion: {
    type: Boolean,
    default: false
  },
  activeName: {
    type: String,
    default: ''
  },
  openNames: {
    type: Array,
    default: () => []
  }
})
const menuRef = ref()
const openedNames: Ref<any[]> = ref([])

const textColor = computed(() => {
  return props.theme === 'dark' ? '#fff' : '#495060'
})

const activeNameCom = computed(() => {
  return props.activeName
})
const openNamesCom = computed(() => {
  return props.openNames
})

watch(activeNameCom, name => {
  if (props.accordion) {
    openedNames.value = getOpenedNamesByActiveName(name)
  } else {
    openedNames.value = getUnion(openedNames.value, getOpenedNamesByActiveName(name))
  }
})

watch(openNamesCom.value, newNames => {
  openedNames.value = newNames
})

watch(openedNames.value, () => {
  if (menuRef.value) {
    nextTick(() => {
      menuRef.value.updateOpened()
    })
  }
})

const handleSelect = (name: any) => {
  emit('on-select', name)
}
const getOpenedNamesByActiveName = (name: any) => {
  return currentRoute.matched.map(item => item.name).filter(item => item !== name)
}
const showChildren = (item: any) => {
  return item.children && (item.children.length > 1 || (item.meta && item.meta.showAlways))
}
const getNameOrHref = (item: any, children0?: any) => {
  return item.href ? `isTurnByHref_${item.href}` : children0 ? item.children[0].name : item.name
}

// const updateOpenName = name => {
//   if (name === this.$config.homeName) this.openedNames = []
//   else this.openedNames = getOpenedNamesByActiveName(name)
// }

onMounted(() => {
  openedNames.value = getUnion(openedNames.value, getOpenedNamesByActiveName(name))
})
</script>
<style lang="less">
.side-menu-wrapper {
  user-select: none;
  .ivu-menu.ivu-menu-light.ivu-menu-vertical::after {
    display: none;
  }
  .menu-collapsed {
    padding-top: 10px;

    .ivu-dropdown {
      width: 100%;
      .ivu-dropdown-rel a {
        width: 100%;
      }
    }
    .ivu-tooltip {
      width: 100%;
      .ivu-tooltip-rel {
        width: 100%;
      }
      .ivu-tooltip-popper .ivu-tooltip-content {
        .ivu-tooltip-arrow {
          border-right-color: #fff;
        }
        .ivu-tooltip-inner {
          background: #fff;
          color: #495060;
        }
      }
    }
  }
  a.drop-menu-a {
    display: inline-block;
    padding: 6px 15px;
    width: 100%;
    text-align: center;
    color: #495060;
  }
}
.menu-title {
  padding-left: 6px;
}
</style>
