<template>
  <Submenu :name="`${parentName}`">
    <template #title>
      <common-icon :size="iconSize" :type="parentItem.icon || ''" />
      <span class="m-l-10">{{ showTitle(parentItem) }}</span>
    </template>
    <template v-for="item in children">
      <template v-if="item.children && item.children.length === 1">
        <side-menu-item v-if="showChildren(item)" :key="`menu-${item.name}`" :parent-item="item" />
        <menu-item v-else :key="`menu-${item.children[0].name}`" :name="getNameOrHref(item, true)">
          <common-icon :size="iconSize" :type="item.children[0].icon || ''" />
          <span>{{ showTitle(item.children[0]) }}</span>
        </menu-item>
      </template>
      <template v-else>
        <side-menu-item v-if="showChildren(item)" :key="`menu-${item.name}`" :parent-item="item" />
        <template v-else>
          <menu-item :key="`menu-${item.name}`" :name="getNameOrHref(item)">
            <common-icon :size="iconSize" :type="item.icon || ''" />
            <span>{{ showTitle(item) }}</span>
          </menu-item>
        </template>
      </template>
    </template>
  </Submenu>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { showTitle } from '@/libs/utils'
import CommonIcon from '../common-icon/common-icon.vue'

const props = defineProps({
  parentItem: {
    type: Object,
    default: () => {
      return {}
    }
  },
  iconSize: {
    type: Number,
    default: 14
  }
})

const parentName = computed(() => {
  return props.parentItem.name
})

const children = computed(() => {
  return props.parentItem.children
})

const getNameOrHref = (item: any, children0?: any) => {
  return item.href ? `isTurnByHref_${item.href}` : children0 ? item.children[0].name : item.name
}

const showChildren = (item: any) => {
  return item.children && (item.children.length > 1 || (item.meta && item.meta.showAlways))
}
</script>
